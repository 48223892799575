import { ThemePlusTypes } from "@styles/types";
import { PalletThemeColors } from "@styles/utils";

// Here we should import all the individual theme objects.
import sidebarExtended from "./sidebar";
import accordion from "./accordion";
import accountAndSettings from "./accountsAndSettings";
import achievementsStats from "./achievementsStats";
import actionDrawer from "./actionDrawer";
import activeButton from "./activeButton";
import alertBox from "./alertBox";
import announcements from "./announcements";
import announcementsDrawer from "./announcementsDrawer";
import animatedProgressBar from "./animatedProgressBar";
import arrowButton from "./arrowButton";
import autocompleteInput from "./autocompleteInput";
import automationsDrawer from "./automationsDrawer";
import availabilityTag from "./availabilityTag";
import avatar from "./avatar";
import borderedSection from "./borderedSection";
import branches from "./branches";
import brandedForms from "./brandedForms";
import brandingLabel from "./brandingLabel";
import calendar from "./calendar";
import carousel from "./carousel";
import catalog from "./catalog";
import colorPalette from "./colors";
import colorPicker from "./colorPicker";
import categories from "./categories";
import categoryCard from "./categoryCard";
import certificateCard from "./certificateCard";
import certificateTemplateCard from "./certificateTemplateCard";
import chip from "./chip";
import copyToClipboard from "./copyToClipboard";
import courseEdit from "./courseEdit";
import courseCard from "./courseCard";
import courseOverview from "./courseOverview";
import courseToc from "./courseToc";
import courseUnits from "./courseUnits";
import customHomePage from "./customHomePage";
import customLink from "./customLink";
import customBadge from "./customBadge";
import customCourseFields from "./customCourseFields";
import customUserFields from "./customUserFields";
import dateInput from "./dateInput";
import dropdown from "./dropdown";
import discussions from "./discussions";
import dialogBox from "./dialogBox";
import editableText from "./editableText";
import editor from "./editor";
import formfields from "./formfields";
import featuredCard from "./featuredCard";
import ftpConfigDrawer from "./ftpConfigDrawer";
import fileCard from "./fileCard";
import fileInput from "./fileInput";
import fileList from "./fileList";
import importResults from "./importResults";
import gamification from "./gamification";
import gamificationDrawer from "./gamificationDrawer";
import gamificationNotification from "./gamificationNotification";
import general from "./general";
import groups from "./groups";
import icon from "./icon";
import infoGrid from "./infoGrid";
import infoPanel from "./infoPanel";
import innerPage from "./innerPage";
import imageEditor from "./imageEditor";
import imageSelector from "./imageSelector";
import iltContainer from "./iltContainer";
import leaderBoard from "./leaderBoard";
import mainHeader from "./mainHeader";
import massActionProgressBar from "./massActionProgressBar";
import messages from "./messages";
import microstats from "./microstats";
import modal from "./modal";
import multiSelect from "./multiSelect";
import notificationsWidget from "./notificationsWidget";
import onboardingIntro from "./onboardingIntro";
import passwordStrengthBar from "./passwordStrengthBar";
import payments from "./payments";
import pressedRectangle from "./pressedRectangle";
import profile from "./profile";
import pdfPreview from "./pdfPreview";
import price from "./price";
import progressDrawer from "./progressDrawer";
import quickActionsWidget from "./quickActionsWidget";
import rangeInput from "./rangeInput";
import rating from "./rating";
import recording from "./recording";
import reminderWidget from "./reminderWidget";
import resultsPage from "./resultsPage";
import reqardsTabWidget from "./reqardsTabWidget";
import selectCard from "./selectCard";
import scrollbar from "./scrollbar";
import signInPage from "./signInPage";
import stats from "./stats";
import statusTag from "./statusTag";
import stripedProgressBar from "./stripedProgressBar";
import screenSizeAlert from "./screenSizeAlert";
import terms from "./terms";
import textCard from "./textCard";
import textWithIcon from "./textWithIcon";
import timeLimitBar from "./timeLimitBar";
import timeline from "./timeline";
import toggleButtonView from "./toggleButtonView";
import trainingMatrix from "./trainingMatrix";
import unitTopNav from "./unitTopNav";
import unitFooter from "./unitFooter";
import units from "./units";
import unitsToC from "./unitsToC";
import uploadArea from "./uploadArea";
import upsell from "./upsell";
import users from "./users";
import usersSettings from "./usersSettings";
import widgetsDashboard from "./widgetsDashboard";
import widget from "./widget";
import widgets from "./widgets";
import scheduledReportsDrawer from "./scheduledReportsDrawer";
import assignment from "./assignment";
import courseStore from "./courseStore";
import referral from "./referral";
import subscription from "./subscription";
import customReport from "./customReport";
import reorderColumns from "./reorderColumns";

export const getPlusTheme = (colors: PalletThemeColors): ThemePlusTypes => {
  return {
    accordion: accordion(colors),
    accountAndSettings: accountAndSettings(colors),
    achievementsStats: achievementsStats(colors),
    actionDrawer: actionDrawer(colors),
    activeButton: activeButton(colors),
    alertBox: alertBox(colors),
    announcements: announcements(colors),
    announcementsDrawer: announcementsDrawer(colors),
    animatedProgressBar: animatedProgressBar(colors),
    arrowButton: arrowButton(colors),
    assignment: assignment(colors),
    autocompleteInput: autocompleteInput(colors),
    automationsDrawer: automationsDrawer(colors),
    availabilityTag: availabilityTag(colors),
    avatar: avatar(colors),
    borderedSection: borderedSection(colors),
    branches: branches(colors),
    brandedForms: brandedForms(colors),
    brandingLabel: brandingLabel(colors),
    calendar: calendar(colors),
    carousel: carousel(colors),
    catalog: catalog(colors),
    colorPicker: colorPicker(colors),
    colors: colorPalette(colors),
    categories: categories(colors),
    categoryCard: categoryCard(colors),
    certificateCard: certificateCard(colors),
    certificateTemplateCard: certificateTemplateCard(colors),
    chip: chip(colors),
    copyToClipboard: copyToClipboard(colors),
    courseEdit: courseEdit(colors),
    courseCard: courseCard(colors),
    courseStore: courseStore(colors),
    courseOverview: courseOverview(colors),
    courseToc: courseToc(colors),
    courseUnits: courseUnits(colors),
    customHomePage: customHomePage(colors),
    customLink: customLink(colors),
    customReport: customReport(colors),
    customBadge: customBadge(colors),
    customCourseFields: customCourseFields(colors),
    customUserFields: customUserFields(colors),
    dateInput: dateInput(colors),
    dropdown: dropdown(colors),
    discussions: discussions(colors),
    dialogBox: dialogBox(colors),
    editableText: editableText(colors),
    editor: editor(colors),
    formFields: formfields(colors),
    featuredCard: featuredCard(colors),
    ftpConfigDrawer: ftpConfigDrawer(colors),
    fileCard: fileCard(colors),
    fileInput: fileInput(colors),
    fileList: fileList(colors),
    gamification: gamification(colors),
    gamificationDrawer: gamificationDrawer(colors),
    gamificationNotification: gamificationNotification(colors),
    general: general(colors),
    groups: groups(colors),
    icon: icon(colors),
    infoGrid: infoGrid(colors),
    infoPanel: infoPanel(colors),
    importResults: importResults(colors),
    imageEditor: imageEditor(colors),
    innerPage: innerPage(colors),
    imageSelector: imageSelector(colors),
    iltContainer: iltContainer(colors),
    leaderboard: leaderBoard(colors),
    mainHeader: mainHeader(colors),
    massActionProgressBar: massActionProgressBar(colors),
    messages: messages(colors),
    microstats: microstats(colors),
    modal: modal(colors),
    multiSelect: multiSelect(colors),
    notificationsWidget: notificationsWidget(colors),
    onboardingIntro: onboardingIntro(colors),
    passwordStrengthBar: passwordStrengthBar(colors),
    payments: payments(colors),
    pressedRectangle: pressedRectangle(colors),
    profile: profile(colors),
    pdfPreview: pdfPreview(colors),
    price: price(colors),
    progressDrawer: progressDrawer(colors),
    quickActionsWidget: quickActionsWidget(colors),
    rangeInput: rangeInput(colors),
    rating: rating(colors),
    recording: recording(colors),
    reminderWidget: reminderWidget(colors),
    referral: referral(colors),
    reorderColumns: reorderColumns(colors),
    resultsPage: resultsPage(colors),
    rewardsTabWidget: reqardsTabWidget(colors),
    selectCard: selectCard(colors),
    scheduledReportsDrawer: scheduledReportsDrawer(colors),
    scrollbar: scrollbar(colors),
    signInPage: signInPage(colors),
    sidebarExtended: sidebarExtended(colors),
    stats: stats(colors),
    statusTag: statusTag(colors),
    stripedProgressBar: stripedProgressBar(colors),
    screenSizeAlert: screenSizeAlert(colors),
    subscription: subscription(colors),
    terms: terms(colors),
    textCard: textCard(colors),
    textWithIcon: textWithIcon(colors),
    timeLimitBar: timeLimitBar(colors),
    timeline: timeline(colors),
    toggleButtonsView: toggleButtonView(colors),
    trainingMatrix: trainingMatrix(colors),
    unitTopNav: unitTopNav(colors),
    unitFooter: unitFooter(colors),
    units: units(colors),
    unitsToC: unitsToC(colors),
    uploadArea: uploadArea(colors),
    upsell: upsell(colors),
    users: users(colors),
    usersSettings: usersSettings(colors),
    widgetsDashboard: widgetsDashboard(colors),
    widget: widget(colors),
    widgets: widgets(colors),
  };
};
